<script lang="ts">
    /* eslint-disable @typescript-eslint/no-unsafe-call */
    import { onDestroy, onMount, setContext, tick } from 'svelte';
    import { Bubble, BubbleSource, getContextualReportingId, getLastSystemBubble, RequestType } from './bubble';
    import TopBar from './components/TopBar.svelte';
    import { Config, mergeConfigs } from './config';
    import type { BubbleEvent, InputEvent } from './event';
    import { ChatHistory, ChatHistorySymbol } from './session';

    import { hexToRgb } from './color';
    import BrandIcon from './components/BrandIcon.svelte';

    import { fade } from 'svelte/transition';
    import { blurBackdrop } from './transition';

    import { writable, type Writable } from 'svelte/store';
    import {
        Bridge,
        BridgeConsumeType,
        BridgePublishType,
        type CustomMessage,
        type Callout,
        type ExternalEvent
    } from './bridge';
    import MainContent from './components/MainContent.svelte';
    import { Layout } from './layout';
    import { type EventRequest, type QueryRequest, sendEvent } from './query';
    import { Sound } from './sound';
    import type { JSONValue } from './types';

    export let config: Config;
    export let bridge: Bridge = new Bridge();

    let parsedConfig = new Config();

    const chatHistory: Writable<ChatHistory> = writable(new ChatHistory(bridge));
    setContext(ChatHistorySymbol, chatHistory);

    const sound = new Sound();
    const layout = new Layout();
    const { vh, hw } = layout;

    let [gradientR, gradientG, gradientB, gradientA] = [0, 0, 0, 1];
    let [accentR, accentG, accentB] = [0, 0, 0];

    let bubbles = [];
    let opened = false;
    let maximized = false;
    let convStart = '';

    let rootNode: HTMLDivElement;
    let isChildOfBody = false;
    let loading = false;

    let closing = false;

    let resetKey = 0;
    let lastInput = '';

    let abortController: AbortController;
    const initializeAbortController = () => {
        abortController = new AbortController();
        abortController.signal.addEventListener('abort', () => {
            abortController = initializeAbortController();
        });
        return abortController;
    };

    abortController = initializeAbortController();
    const abortFetch = () => {
        abortController.abort();
    };

    let callout: Callout = null;
    let calloutInterval: ReturnType<typeof setInterval> = null;

    const refresh = () => {
        initData();
        mountInit();
        resetKey += 1;
    };

    const onClear = () => {
        clear();
    };

    const clear = (remount = true) => {
        $chatHistory.stateReset();
        initData();
        lastInput = '';
        if (remount) {
            mountInit();
        }
        resetKey += 1;
        sound.pause();
        abortFetch();
    };

    const reset = () => {
        closing = true;
        $chatHistory.fullReset(parsedConfig);
        initData();
        lastInput = '';
        mountInit();
        clearCallout();
        resetKey += 1;
        sound.pause();
        abortFetch();
        tick().then(() => {
            closing = false;
        });
    };

    const reinit = () => {
        $chatHistory.reinit();
        reset();
    };

    const clearCallout = () => {
        if (calloutInterval !== null) {
            clearInterval(calloutInterval);
            calloutInterval = null;
            callout = null;
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onBridgeEvent = (type: string, data: JSONValue): JSONValue => {
        if (type === BridgePublishType.refresh) {
            refresh();
        } else if (type === BridgePublishType.clear) {
            clear();
        } else if (type === BridgePublishType.reset) {
            reset();
        } else if (type === BridgePublishType.open) {
            onOpen();
        } else if (type === BridgePublishType.close) {
            onClose();
        } else if (type === BridgePublishType.reinit) {
            reinit();
        } else if (type === BridgePublishType.callout) {
            if (opened || closing) {
                return {};
            }
            clearCallout();
            tick().then(() => {
                const calloutData = data as unknown as Callout;
                const millis = calloutData.millis ?? 10000;
                calloutInterval = setInterval(() => {
                    callout = null;
                }, millis);
                callout = {
                    text: calloutData.text,
                    millis
                };
            });
        } else if (type === BridgePublishType.event) {
            if (!config.hasPipeline) {
                throw new Error('cannot publish event type without pipeline configuration');
            }
            const externalEvent = data as unknown as ExternalEvent;
            const eventRequest: EventRequest = {
                ...externalEvent,
                conv: $chatHistory.convId,
                user_id: $chatHistory.userId,
                integration_key: config.integrationKey
            };
            sendEvent(eventRequest, config, bridge, abortController).then((res) => {
                const lastConvId = $chatHistory.convId;
                $chatHistory.pipelineConvId = res.conv;
                if (lastConvId !== res.conv) {
                    bridge._sendConsumable(BridgeConsumeType.newConversation, {
                        last_conv_id: lastConvId,
                        user_id: $chatHistory.userId,
                        conv_id: $chatHistory.pipelineConvId
                    });
                }
                bridge._sendConsumable(BridgeConsumeType.event, res);
            });
        } else if (type === BridgePublishType.message) {
            const customMessage = data as unknown as CustomMessage;
            addBubbleToView(Bubble.Create(customMessage.message, customMessage.source ?? BubbleSource.system));
        }
        return {};
    };

    // Maximize if we're open below a mobile breakpoint
    $: if ($hw < 576 && $hw > 0 && !maximized && opened) {
        onResize();
    }

    $: lastSystemBubble = getLastSystemBubble(bubbles);
    $: hasSystemResponse = (lastSystemBubble?.response ?? null) !== null;
    $: responseInScope = hasSystemResponse && (lastSystemBubble.response?.response?.content_id ?? null) !== null;
    $: dropdownOptions = [
        {
            text: $chatHistory.prefersMute ? 'Unmute Sound' : 'Mute Sound',
            hidden: false,
            callback: () => {
                $chatHistory.prefersMute = !$chatHistory.prefersMute;
                sound.pause();
            }
        },
        {
            text: 'Diagnose Last Query',
            hidden: !hasSystemResponse || !parsedConfig.debug || lastSystemBubble.request_type !== RequestType.query,
            callback: () => {
                const id = lastSystemBubble.response.turn;
                const query = (lastSystemBubble.request as QueryRequest).query;
                const contextualReportingId = getContextualReportingId(bubbles, id);
                bridge._sendConsumable('mark_incorrect', { id, query, contextualReportingId });
            }
        },
        {
            text: 'View Conversation',
            hidden: !hasSystemResponse || !parsedConfig.debug || true,
            callback: () => {
                bridge._sendConsumable('view_conversation', $chatHistory.exportConversation());
            }
        },
        {
            text: 'View Last Query',
            hidden: !hasSystemResponse || !parsedConfig.debug || true,
            callback: () => {
                bridge._sendConsumable('view_query', $chatHistory.exportLatestQuery());
            }
        },
        {
            text: 'View in Query Log',
            hidden: !hasSystemResponse || !parsedConfig.debug,
            callback: () => {
                bridge._sendConsumable('navigate_query_log', { id: $chatHistory.lastSystemBubble.response.turn });
            }
        },
        {
            text: 'View in Content Manager',
            hidden: !hasSystemResponse || !responseInScope || !parsedConfig.debug || true,
            callback: () => {
                bridge._sendConsumable('navigate_content_manager', {
                    id: $chatHistory.lastSystemBubble.response.response.content_id
                });
            }
        },
        {
            text: 'Clear Conversation',
            hidden: bubbles.length <= 1,
            callback: () => {
                clear();
            }
        }
    ];

    const onInput = (event: CustomEvent<InputEvent>) => {
        lastInput = event.detail.text;
    };
    const onBubble = (event: CustomEvent<BubbleEvent>) => {
        lastInput = '';
        addBubbleToView(event.detail.bubble);
    };
    const addBubbleToView = (bubble: Bubble) => {
        $chatHistory.addBubble(bubble);
        bubbles.unshift(bubble);

        // eslint-disable-next-line no-self-assign
        bubbles = bubbles;
    };
    const onOpen = () => {
        if (opened) {
            return;
        }
        callout = null;
        opened = true;
        closing = false;
        $chatHistory.opened = opened;
        if (maximized) {
            layout.disableBodyScroll();
        }
        bridge._sendConsumable(BridgeConsumeType.open, {});
    };
    const onClose = () => {
        if (!opened) {
            return;
        }
        opened = false;
        closing = true;
        lastInput = '';
        sound.pause();
        $chatHistory.opened = opened;
        if (maximized) {
            layout.resetBodyScroll();
        }
        bridge._sendConsumable(BridgeConsumeType.close, {});
    };
    const onResize = () => {
        maximized = !maximized;
        $chatHistory.maximized = maximized;
        if (maximized) {
            layout.disableBodyScroll();
        } else {
            layout.resetBodyScroll();
        }
    };
    const windowOutroEnd = () => {
        closing = false;
    };

    const initData = () => {
        parsedConfig = mergeConfigs(parsedConfig, config);
        [gradientR, gradientG, gradientB] = hexToRgb(parsedConfig.window.backgroundColor);
        if (parsedConfig.window.backgroundTransparent) {
            gradientA = 0.7;
        } else {
            gradientA = 1.0;
        }
        [accentR, accentG, accentB] = hexToRgb(parsedConfig.window.accentColor);

        bridge._registerPublishHandler(onBridgeEvent);

        $chatHistory.load(parsedConfig);
        $chatHistory.appKey = parsedConfig.appKey;

        bubbles = [...$chatHistory.bubbles];
        opened = $chatHistory.opened;
        maximized = $chatHistory.maximized;
        convStart = $chatHistory.convStart;
    };
    initData();
    onMount(() => {
        mountInit();
        isChildOfBody = rootNode.parentElement === document.body;
        layout.onMount(isChildOfBody, rootNode.parentElement);
        if (opened && maximized) {
            layout.disableBodyScroll();
        }
    });
    const mountInit = () => {
        if (parsedConfig.bubbles?.termsVisibility && !$chatHistory.consentedToTerms) {
            if (bubbles.length) {
                clear(false);
            }
            addBubbleToView(Bubble.CreateTerms(parsedConfig.bubbles.termsMessage, parsedConfig.bubbles.termsLinks));
            $chatHistory.consentedToTerms = true;
            addBubbleToView(Bubble.CreateWelcome(parsedConfig.bubbles.welcomeMessage));
        } else if (!bubbles.length) {
            addBubbleToView(Bubble.CreateWelcome(parsedConfig.bubbles.welcomeMessage));
        }
    };
    onDestroy(() => {
        if (parsedConfig.clearSessionOnDestroy) {
            $chatHistory.stateReset();
        }
        sound.pause();
        abortFetch();
    });
</script>

{#key resetKey}
    <div id="knowbl-chat-widget" data-testid="widget" bind:this={rootNode}>
        <div
            style="
--knowbl-var-window-blur: {parsedConfig.window.blur}px;
--knowbl-var-window-z-index: {parsedConfig.window.zIndex};
--knowbl-var-window-full-width: {parsedConfig.window.fullWidth}px;
--knowbl-var-window-side-width: {parsedConfig.window.sideWidth}px;
--knowbl-var-window-open-button-color: {parsedConfig.window.openButtonColor};
--knowbl-var-window-background-color: rgba({gradientR}, {gradientG}, {gradientB}, {gradientA});
--knowbl-var-window-background-gradient: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba({gradientR}, {gradientG}, {gradientB}, 1.0) 100%);
--knowbl-var-window-accent-color: rgb({accentR}, {accentG}, {accentB});
--knowbl-var-window-accent-light-color: rgba({accentR}, {accentG}, {accentB}, 0.4);

--knowbl-var-close-icon-color: {parsedConfig.controlBar.closeIconColor};
--knowbl-var-close-icon-hover-color: {parsedConfig.controlBar.closeIconHoverColor};

--knowbl-var-text-font-family: {parsedConfig.window.fontFamily};
--knowbl-var-text-color-title: {parsedConfig.concierge.titleColor};
--knowbl-var-text-color-subtitle: {parsedConfig.concierge.subtitleColor};

--knowbl-var-bubble-max-width: {parsedConfig.bubbles.maxWidth}px;
--knowbl-var-bubble-color-concierge-text: {parsedConfig.bubbles.conciergeTextColor};
--knowbl-var-bubble-color-concierge-link-text: {parsedConfig.bubbles.conciergeLinkTextColor};

--knowbl-var-color-placeholder-text: {parsedConfig.controlBar.placeholderColor};
--knowbl-var-color-input-text: {parsedConfig.controlBar.textColor};

--knowbl-var-vh: {$vh}px;
"
            class="{isChildOfBody ? 'knowbl--app-fixed knowbl--app-z' : 'knowbl--app-absolute'} knowbl--app-screen"
            on:click={sound.init}
            on:keypress={sound.init}
        >
            {#if opened}
                <div
                    class="knowbl--app-window knowbl--app-pointer {isChildOfBody
                        ? 'knowbl--app-window-body'
                        : 'knowbl--app-window-subchild'} {parsedConfig.window.backgroundGradient
                        ? 'knowbl--app-gradient'
                        : 'knowbl--app-color'} {parsedConfig.window.backgroundTransparent
                        ? 'knowbl--app-blurred'
                        : 'knowbl--app-opaque'}"
                    class:knowbl--app-large={maximized}
                    class:knowbl--app-small={!maximized}
                    transition:blurBackdrop|local={{
                        duration: 250,
                        amount: parsedConfig.window.blur
                    }}
                    on:outroend={windowOutroEnd}
                >
                    <div class="knowbl--app-container" transition:fade|local={{ duration: 500 }}>
                        {#if !parsedConfig.window.omitHeader}
                            <TopBar
                                conciergeName={parsedConfig.concierge.name}
                                {convStart}
                                {maximized}
                                config={parsedConfig}
                                on:close={onClose}
                                on:resize={onResize}
                            />
                        {/if}
                        <div class="knowbl--app-subcontainer">
                            <MainContent
                                on:bubble={onBubble}
                                on:clear={onClear}
                                on:input={onInput}
                                bind:loading
                                config={parsedConfig}
                                {bridge}
                                {sound}
                                {bubbles}
                                {isChildOfBody}
                                {dropdownOptions}
                                {abortController}
                                {lastInput}
                            />
                        </div>
                    </div>
                </div>
            {:else if !closing}
                <div
                    class="{isChildOfBody
                        ? 'knowbl--app-fixed'
                        : 'knowbl--app-absolute'} knowbl--app-icon knowbl--app-pointer"
                    data-testid="brand-icon"
                    transition:fade|local={{ duration: 300 }}
                >
                    <BrandIcon {callout} icon={parsedConfig.logos.icon} on:open={onOpen} />
                </div>
            {/if}
        </div>
    </div>
{/key}

<style>
    :global(#knowbl-chat-widget) :global(*:where(:not(img, svg, b, em, code, u, del, sup, sub, a):not(svg *))) {
        all: initial;
        display: revert;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--app-screen) {
        pointer-events: none;
        width: 100%;
        height: 100%;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--app-z) {
        z-index: var(--knowbl-var-window-z-index);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--app-pointer) {
        pointer-events: all;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--app-icon) {
        pointer-events: none;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--app-window-body) {
        height: calc(var(--knowbl-var-vh, 1vh) * 100);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--app-window-subchild) {
        height: 100%;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--app-large) {
        transition: max-width 0.5s;
        transition-timing-function: ease-in-out;
        max-width: 100%;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--app-small) {
        transition: max-width 0.5s;
        transition-timing-function: ease-in-out;
        max-width: var(--knowbl-var-window-side-width);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--app-container) {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--app-subcontainer) {
        max-width: var(--knowbl-var-window-full-width);
        margin: 0 auto;
        width: 100%;
        flex: 1 1;
        min-height: 0;
        display: flex;
        flex-direction: column;
        padding: 0;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--app-window) {
        margin-left: auto;
        margin-right: 0;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--app-gradient) {
        background: var(--knowbl-var-window-background-gradient);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--app-color) {
        background: var(--knowbl-var-window-background-color);
    }
    :global(#knowbl-chat-widget) :global(.knowbl--app-blurred) {
        backdrop-filter: blur(var(--knowbl-var-window-blur));
        -webkit-backdrop-filter: blur(var(--knowbl-var-window-blur));
    }
    @media only screen and (max-width: 576px) {
        :global(#knowbl-chat-widget) :global(.knowbl--app-window) {
            max-width: 100%;
        }
    }
    :global(#knowbl-chat-widget) :global(.knowbl--app-fixed) {
        position: fixed;
        right: 0;
        bottom: 0;
    }
    :global(#knowbl-chat-widget) :global(.knowbl--app-absolute) {
        position: absolute;
        right: 0;
        bottom: 0;
    }
</style>
