import type { JsonNode, JsonParagraphNode } from './jsonNode';
import type { Link } from './types';

class ConciergeConfig {
    name?: string;
    titleColor?: string;
    subtitleColor?: string;
}

class ControlBarConfig {
    textColor?: string;
    placeholderText?: string;
    placeholderColor?: string;
    closeIconColor?: string;
    closeIconHoverColor?: string;
}

class WindowConfig {
    blur?: number;
    zIndex?: number;
    fullWidth?: number;
    sideWidth?: number;
    fontFamily?: string;
    startMaximized?: boolean;
    startOpened?: boolean;
    omitHeader?: boolean;
    preferMute?: boolean;
    backgroundColor?: string;
    backgroundGradient?: boolean;
    backgroundTransparent?: boolean;
    accentColor?: string;
    openButtonColor?: string;
}

class ContentSourceLinksConfig {
    enabled?: boolean;
    label?: string;
}

class BubblesConfig {
    maxWidth?: number;
    feedbackEnabled?: boolean;
    welcomeMessage?: string | JsonNode[];
    errorMessage?: string | JsonNode[];
    conciergeTextColor?: string;
    conciergeLinkTextColor?: string;
    termsMessage?: string[];
    termsLinks?: Link[];
    termsVisibility?: 'show' | 'require' | null;
    contentSourceLinks?: ContentSourceLinksConfig;
}

class LogosConfig {
    brand?: string;
    icon?: string;
}

export class Config {
    host?: string;
    hasPipeline?: boolean;
    queryEndpoint?: string;
    analyticsEndpoint?: string;
    appKey?: string;
    integrationKey?: string;
    extraHeaders?: Record<string, string>;
    extraParams?: Map<string, string>;
    debug?: boolean;
    clearSessionOnDestroy?: boolean;
    sessionStorageKey?: string;
    localStorageKey?: string;
    locale?: string;
    type?: string;
    responseTimeout?: number;
    workspaceId?: string;
    ttsGender?: 'male' | 'female' = 'female';
    ttsVoice?: 'a' | 'b' | 'c' | 'd' = 'a';
    concierge?: ConciergeConfig = {
        name: 'Chat Widget',
        titleColor: '#FFFFFF',
        subtitleColor: '#CCCCCC'
    };
    controlBar?: ControlBarConfig = {
        textColor: '#f6f6f6',
        placeholderText: 'This is a placeholder.',
        placeholderColor: '#999999',
        closeIconColor: '#DDDDDD',
        closeIconHoverColor: '#F6F6F6'
    };
    window?: WindowConfig = {
        blur: 15,
        zIndex: 2147483647,
        fullWidth: 800,
        sideWidth: 500,
        fontFamily: "'Poppins', sans-serif",
        startMaximized: false,
        startOpened: false,
        omitHeader: false,
        preferMute: false,
        backgroundColor: '#000000',
        backgroundGradient: true,
        backgroundTransparent: true,
        accentColor: '#999999',
        openButtonColor: '#000000'
    };
    bubbles?: BubblesConfig = {
        maxWidth: 360,
        feedbackEnabled: false,
        welcomeMessage: 'This is a placeholder.',
        errorMessage: 'I am unable to assist right now. Please try again later.',
        conciergeTextColor: '#f6f6f6',
        conciergeLinkTextColor: '#3e9ef8',
        termsVisibility: null,
        termsMessage: [],
        termsLinks: [],
        contentSourceLinks: {
            enabled: false,
            label: null
        }
    };
    logos?: LogosConfig = {
        brand: null,
        icon: null
    };
    // @deprecated, use footer instead
    footerPrefix?: string;
    // @deprecated, use footer instead
    footerLinks?: Link[];
    // See generating JSON Nodes in README.md
    footer?: JsonParagraphNode[];

    constructor() {
        this.queryEndpoint = '/api/query';
        this.analyticsEndpoint = '/analytics';
    }
}

export const mergeConfigs = (oldConfig: Config, newConfig: Config) => {
    return {
        ...(oldConfig ?? {}),
        ...(newConfig ?? {}),
        concierge: {
            ...(oldConfig.concierge ?? {}),
            ...(newConfig.concierge ?? {})
        },
        controlBar: {
            ...(oldConfig.controlBar ?? {}),
            ...(newConfig.controlBar ?? {})
        },
        window: {
            ...(oldConfig.window ?? {}),
            ...(newConfig.window ?? {})
        },
        bubbles: {
            ...(oldConfig.bubbles ?? {}),
            ...(newConfig.bubbles ?? {})
        },
        logos: {
            ...(oldConfig.logos ?? {}),
            ...(newConfig.logos ?? {})
        }
    };
};

export const resolveHost = (host: string, scheme = 'https:') =>
    (new RegExp(`^${scheme}//`, 'i').test(host) ? host : `${scheme}//${host}`).replace(/\/+$/, '');

/* // TODO(sean)
export const validateConfig = (config: Config) => {

};
*/
